import React from 'react';
import BackgroundImage from 'gatsby-background-image'

const SubHero = ({title, subTitle, color, bgImage}) => {
  let content = (
    <div className="max-w-screen-2xl mx-auto px-4 md:px-8 flex flex-wrap justify-between items-end pt-12 md:pt-32 pb-12 mb-6 relative z-10">
      <div style={{color}} className="leading-tight md:pr-8">     
        <h2 className="text-lg sm:text-2xl font-light">{subTitle}</h2>
        <h1 className="font-black text-4xl sm:text-6xl">{title}</h1>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="414" height="111.843" viewBox="0 0 414 111.843">
        <g id="Group_61" data-name="Group 61" transform="translate(-934 -4886.27)">
          <path id="garden" d="M113.322,84.251A18.729,18.729,0,0,0,121.129,48.5,24.987,24.987,0,0,0,103.782,17.14a20.025,20.025,0,0,0-39.631,0A25,25,0,0,0,46.805,48.5a18.73,18.73,0,0,0,7.807,35.756h26.08v21.041H53.678v6.551h60.579v-6.551H87.242V84.251h26.079ZM54.612,77.7a12.181,12.181,0,0,1-2.793-24.037l3.94-.924-1.725-3.661A18.427,18.427,0,0,1,67.745,23.056l2.72-.439.033-2.755a13.47,13.47,0,0,1,26.938,0l.033,2.755,2.72.439A18.427,18.427,0,0,1,113.9,49.079l-1.725,3.661,3.94.924A12.181,12.181,0,0,1,113.322,77.7H87.242V59.483l12-12-4.632-4.632-7.364,7.364V28.513H80.691V38.4l-7.364-7.364L68.7,35.673l12,12V62.033l-7.364-7.364L68.695,59.3l12,12v6.4H54.612Z" transform="translate(1003.857 4886.27)" fill={color} opacity="0.14"/>
          <path id="garden-2" data-name="garden" d="M113.322,84.251A18.729,18.729,0,0,0,121.129,48.5,24.987,24.987,0,0,0,103.782,17.14a20.025,20.025,0,0,0-39.631,0A25,25,0,0,0,46.805,48.5a18.73,18.73,0,0,0,7.807,35.756h26.08v21.041H53.678v6.551h60.579v-6.551H87.242V84.251h26.079ZM54.612,77.7a12.181,12.181,0,0,1-2.793-24.037l3.94-.924-1.725-3.661A18.427,18.427,0,0,1,67.745,23.056l2.72-.439.033-2.755a13.47,13.47,0,0,1,26.938,0l.033,2.755,2.72.439A18.427,18.427,0,0,1,113.9,49.079l-1.725,3.661,3.94.924A12.181,12.181,0,0,1,113.322,77.7H87.242V59.483l12-12-4.632-4.632-7.364,7.364V28.513H80.691V38.4l-7.364-7.364L68.7,35.673l12,12V62.033l-7.364-7.364L68.695,59.3l12,12v6.4H54.612Z" transform="translate(898.12 4886.27)" fill={color} opacity="0.14"/>
          <path id="garden-3" data-name="garden" d="M113.322,84.251A18.729,18.729,0,0,0,121.129,48.5,24.987,24.987,0,0,0,103.782,17.14a20.025,20.025,0,0,0-39.631,0A25,25,0,0,0,46.805,48.5a18.73,18.73,0,0,0,7.807,35.756h26.08v21.041H53.678v6.551h60.579v-6.551H87.242V84.251h26.079ZM54.612,77.7a12.181,12.181,0,0,1-2.793-24.037l3.94-.924-1.725-3.661A18.427,18.427,0,0,1,67.745,23.056l2.72-.439.033-2.755a13.47,13.47,0,0,1,26.938,0l.033,2.755,2.72.439A18.427,18.427,0,0,1,113.9,49.079l-1.725,3.661,3.94.924A12.181,12.181,0,0,1,113.322,77.7H87.242V59.483l12-12-4.632-4.632-7.364,7.364V28.513H80.691V38.4l-7.364-7.364L68.7,35.673l12,12V62.033l-7.364-7.364L68.695,59.3l12,12v6.4H54.612Z" transform="translate(1110.209 4886.27)" fill={color} opacity="0.14"/>
          <path id="garden-4" data-name="garden" d="M113.322,84.251A18.729,18.729,0,0,0,121.129,48.5,24.987,24.987,0,0,0,103.782,17.14a20.025,20.025,0,0,0-39.631,0A25,25,0,0,0,46.805,48.5a18.73,18.73,0,0,0,7.807,35.756h26.08v21.041H53.678v6.551h60.579v-6.551H87.242V84.251h26.079ZM54.612,77.7a12.181,12.181,0,0,1-2.793-24.037l3.94-.924-1.725-3.661A18.427,18.427,0,0,1,67.745,23.056l2.72-.439.033-2.755a13.47,13.47,0,0,1,26.938,0l.033,2.755,2.72.439A18.427,18.427,0,0,1,113.9,49.079l-1.725,3.661,3.94.924A12.181,12.181,0,0,1,113.322,77.7H87.242V59.483l12-12-4.632-4.632-7.364,7.364V28.513H80.691V38.4l-7.364-7.364L68.7,35.673l12,12V62.033l-7.364-7.364L68.695,59.3l12,12v6.4H54.612Z" transform="translate(1215.946 4886.27)" fill={color} opacity="0.14"/>
        </g>
      </svg>
    </div>
  )
  return (
    <div >
      {bgImage ? 
        <BackgroundImage
          Tag="section"
          className="relative"
          fluid={bgImage}
        >
          {content}
          <div className="bg-primary-darker inset-0 opacity-75 absolute"/>
        </BackgroundImage>
        :
        <div className="bg-gray">
          {content}
        </div>
      }
    </div>
  );
}

export default SubHero;
